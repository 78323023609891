import classNames from "classnames";
import React, { Component } from "react";

export default class ReportCircleChart extends Component {
  render() {
    const { previousValue, size, stroke, showLine, value, gold } = this.props;

    return (
      <div className={classNames("report_circle_chart", { gold: gold })}>
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <circle
            className="report_circle_chart__default"
            cx={size / 2}
            cy={size / 2}
            r={size / 2 - stroke}
            fill="none"
            strokeWidth={stroke}
          />
          <circle
            className="report_circle_chart__highlight"
            transform={`rotate(-90, ${size / 2}, ${size / 2})`}
            strokeDasharray="100"
            strokeDashoffset={100 - value}
            cx={size / 2}
            cy={size / 2}
            r={size / 2 - stroke}
            fill="none"
            strokeWidth={stroke}
            pathLength="100"
          />
          {previousValue && (
            <circle
              className="report_circle_chart__highlight--previous"
              transform={`rotate(-90, ${size / 2}, ${size / 2})`}
              strokeDasharray="100"
              strokeDashoffset={100 - previousValue}
              cx={size / 2}
              cy={size / 2}
              r={size / 2 - stroke - stroke / 4}
              fill="none"
              strokeWidth={stroke / 2}
              pathLength="100"
            />
          )}
          {showLine && (
            <line
              x1={stroke / 2 + 1}
              y1={size / 2}
              x2={stroke * 1.5 - 1}
              y2={size / 2}
              className="report_circle_chart__line"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
          )}
          <text
            className="report_circle_chart__label"
            x="50%"
            y="50%"
            textAnchor="middle"
            dy=".4em"
          >
            {value}%
          </text>
        </svg>
      </div>
    );
  }
}
